/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';


import { ProductSearchResponse } from 'src/types/product/product';
import { ProductSearchParams, ProductSuggestParams } from 'src/types/product/SearchAndSuggestionsParams';
import { SearchSuggestionResponse } from 'src/types/product/SearchSuggestionResponse';
import { normalizeProductSearch, normalizeProductSuggestions } from 'src/utils/formatters/normalizeProductSearchAndSuggestions';
import { buildSearchProductQueryParams } from 'src/utils/getters/buildExtraSearchProductFilter';
import getSearchBaseURL from 'src/utils/getters/getSearchURL';


export interface ApiSearch {
  axiosInstance: AxiosInstance;
  getSuggestions: ( params: ProductSuggestParams ) => Promise<SearchSuggestionResponse[]>;
  searchProducts: (params: ProductSearchParams) => Promise<ProductSearchResponse>;
}

export function getSearchApi(): ApiSearch {
  const axiosInstance: AxiosInstance = axios.create({
    httpAgent: axios.defaults.httpAgent,
    httpsAgent: axios.defaults.httpsAgent,
    headers: {
      'x-client-id': 'wilis-view'
    }
  });

  return {
    axiosInstance: axiosInstance,
    getSuggestions: async (params: ProductSuggestParams) => {
      const response = await axiosInstance.get(`${getSearchBaseURL()}/suggest`, { params: decamelizeKeys(params) });
      const data: any = camelizeKeys(response.data);
      return normalizeProductSuggestions(data, params);
    },

    searchProducts: async (params: ProductSearchParams) => {
      const response = await axiosInstance.get(`${getSearchBaseURL()}/search/products?${buildSearchProductQueryParams(params)}`);
      const data: any = camelizeKeys(response.data);
      return normalizeProductSearch(data);
    }
  };
}
