import { OrderHistoryFilters } from 'src/redux/customer/orderHistorySlice';

import { PaymentMethod } from '../offer/PaymentMethod';


export interface OrdersOverviewHistoryRequest {
  externalCustomerId: string;
  page: number;
  size: number;
  total?: number;
  status?: OrderStatusRequest;
  keywords?: string;
  searchFrom?: string;
  searchTo?: string;
  payment?: PaymentMethod;
  externalOrderId?: string;
}

export enum OrderStatusRequest {
  ALL = 'ALL',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELED = 'CANCELED'
}

export const createOrdersHistoryFetchRequest = (
  externalCustomerId: string,
  page: number,
  size: number,
  filters: OrderHistoryFilters,
  total: number,
): OrdersOverviewHistoryRequest => {
  return {
    externalCustomerId,
    size: size,
    page: page,
    status: filters.status,
    searchFrom: filters.searchFrom ? formatDate(filters.searchFrom) : undefined,
    searchTo: filters.searchTo ? formatDate(filters.searchTo) : undefined,
    payment: filters.payment ? filters.payment : undefined,
    keywords: filters.keywords,
    total: !total ? undefined : total,
  };
};

export const formatDate = (date: Date): string => {
  return [
    date.getFullYear(),
    ('0' + (date.getMonth() + 1)).slice(-2),
    ('0' + date.getDate()).slice(-2),
  ].join('-');
};
