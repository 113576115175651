import { decamelizeKeys } from 'humps';

import { ExtraSearchProductFilter, ProductSearchParams } from 'src/types/product/SearchAndSuggestionsParams';


type Filter = {
  key: string;
  values: string[];
};

export enum FilterType {
  Hour = 'Stundenangebote',
  Day = 'Tagesangebote',
  Week = 'Wochenangebote',
  Month = 'Monatsangebote',
  Anniversary = 'Jubiläumsangebote',
}

const filterMap: Map<FilterType, Filter> = new Map([
  [
    FilterType.Hour,
    {
      key: 'sales_driver_filter',
      values: ['Angebot der Stunde', 'Nur einmal Angebot'],
    },
  ],
  [
    FilterType.Day,
    {
      key: 'sales_driver_filter',
      values: [
        'Angebot des Tages',
        'Extra des Tages',
        'Trend des Tages',
        'Wochenend-Extra',
        'Wochenend-Special',
      ],
    },
  ],
  [
    FilterType.Week,
    {
      key: 'sales_driver_filter',
      values: ['Joker der Woche', 'Extra der Woche', 'Trend der Woche', 'Angebot der Woche', 'Zusatzangebot', 'Maxi Deal'],
    },
  ],
  [
    FilterType.Month,
    {
      key: 'sales_driver_filter',
      values: ['Produkt des Monats', 'Extra des Monats', 'Trend des Monats', 'Angebot des Monats', 'Maxi Deal'],
    },
  ],
  [
    FilterType.Anniversary,
    {
      key: 'sales_driver_filter',
      values: ['Jubiläumsangebot'],
    },
  ],
]);

const buildExtraSearchProductFilter = (type: FilterType): ExtraSearchProductFilter => {
  const filter = filterMap.get(type) as Filter;
  const filterKey = encodeURI(filter?.key || '');
  return  { [filterKey]: filter?.values?.map(value => encodeURI(value)) || [] };
};

export function buildSearchProductQueryParams(params: ProductSearchParams) {
  // eslint-disable-next-line
  const basicParams: any = decamelizeKeys({ ...params, extraFilters: undefined });
  const extraFilters = params.extraFilters || {};
  const basicQuery = Object.keys(basicParams)
    .map(key => (basicParams[key]) ? `${key}=${basicParams[key]}` : '')
    .filter(value => !!value)
    .join('&');
  const extraParams = Object.keys(extraFilters)
    .map(key => extraFilters[key]?.map(value => `${key}=${value}`)?.join('&') || '')
    .filter(value => !!value)
    .join('&');

  return `${basicQuery}&${extraParams}`;
}

export default buildExtraSearchProductFilter;
