import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import {
  applyMiddleware,
  createStore,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { getApiAddress } from 'src/api/apiAddress';
import { getApiBasket } from 'src/api/apiBasket';
import { getApiCallCenter } from 'src/api/apiCallCenter';
import { getCustomerApi } from 'src/api/apiCustomer';
import { getApiEditOrder } from 'src/api/apiEditOrder';
import { getApiGuestOrder } from 'src/api/apiGuestOrder';
import { getApiNotifications } from 'src/api/apiNotifications';
import { getApiOffer } from 'src/api/apiOffer';
import { getApiOffers } from 'src/api/apiOffers';
import { getApiOrder } from 'src/api/apiOrder';
import { getApiOrderHistory } from 'src/api/apiOrderHistory';
import { getApiProduct } from 'src/api/apiProduct';
import { getSearchApi } from 'src/api/apiSearch';
import { getApiStockHistory } from 'src/api/apiStockHistory';
import { getSubscriptionsManagementApi } from 'src/api/apiSubscriptionsManagement';
import { getApiUpsells } from 'src/api/apiUpsells';
import { getApiVirtualVoucher } from 'src/api/apiVirtualVoucher';
import { getApiVoucher } from 'src/api/apiVoucher';
import { RootStateType } from 'src/constants/types';
import { getApiEditedOrderHistory } from 'src/editedorder/api/apiEditedOrder';
import { getApiLogging } from 'src/logging/loggingService';
import createReduxBeaconMiddleware from 'src/middlewares/beaconMiddleware';
import { getSubscriptionProductLinkApi } from 'src/subscriptionLinksManagement/api/apiSubscriptionProduct';
import getBasketServiceBaseUrl from 'src/utils/getters/getBasketServiceBaseUrl';
import getPDSBaseURL from 'src/utils/getters/getPDSBaseURL';
import getProductOnAirBaseURL from 'src/utils/getters/getProductOnAirBaseURL';

import { ReduxSagaContext } from './ReduxSagaContext';
import { getApiLetterPreview } from '../api/apiLetterPreview';
import Config from '../config';
import { createReducer, reduxInitialState } from '../redux';
import allSagas from '../sagas';


const sagaMiddleware = createSagaMiddleware(
  {
    context: {
      apiCustomer: getCustomerApi(Config.env.customerServiceUrl),
      apiBasket: getApiBasket(getBasketServiceBaseUrl()),
      apiOffer: getApiOffer(Config.env.orderServiceUrl),
      apiCallCenter: getApiCallCenter(Config.env.callCenterServiceUrl),
      apiGuestOrder: getApiGuestOrder(Config.env.orderServiceUrl),
      apiOrder: getApiOrder(Config.env.orderServiceUrl),
      apiAddress: getApiAddress(Config.env.addressCheckURL),
      apiProduct: getApiProduct(getPDSBaseURL(),Config.env.productServiceProxyUrl),
      apiOrderHistory: getApiOrderHistory(Config.env.orderHistoryServiceURL),
      apiStockHistory: getApiStockHistory(Config.env.stockHistoryServiceUrl),
      apiUpsells: getApiUpsells(Config.env.upsells),
      apiOffers: getApiOffers(getProductOnAirBaseURL()),
      apiVirtualVoucher: getApiVirtualVoucher(Config.env.virtualVoucherApiUrl),
      apiVoucher: getApiVoucher(Config.env.osVoucherServiceApiUrl),
      apiNotifications: getApiNotifications(Config.env.wilisPageDefaultEntityId, Config.env.contentfulConfig),
      apiEditOrder: getApiEditOrder(Config.env.orderServiceUrl),
      apiEditedOrder: getApiEditedOrderHistory(Config.env.orderServiceUrl),
      apiLogging: getApiLogging(Config.env.loggingServiceURL),
      apiSubscriptionsManagement: getSubscriptionsManagementApi(Config.env.callCenterServiceUrl),
      apiPreviewLetter: getApiLetterPreview(Config.env.callCenterServiceUrl),
      apiSearch: getSearchApi(),
      apiSubscriptionProduct : getSubscriptionProductLinkApi(Config.env.subscriptionProductServiceUrl),
    } as ReduxSagaContext,
  });

export function configureStore(history: History): Store<RootStateType> {

  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    createReduxBeaconMiddleware(),
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store = createStore<RootStateType, { type: any }, {}, {}>(
    createReducer(history),
    reduxInitialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  allSagas.map((saga) => sagaMiddleware.run(saga));

  return store;

}
