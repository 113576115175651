import {
  call,
  getContext,
  put,
  takeLatest
} from 'redux-saga/effects';

import { showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { ApiSubscriptionProductLink } from 'src/subscriptionLinksManagement/api/apiSubscriptionProduct';
import {
  createSubscriptionProductLinkAction,
  CreateSubscriptionProductLinkAction,
  createSubscriptionProductLinkSuccess,
  createSubscriptionProductLinkFailed,
  closeModal
} from 'src/subscriptionLinksManagement/redux/subscriptionLinksManagementSlice';
import { SubscriptionProductLink } from 'src/subscriptionLinksManagement/types/subscriptionLinksManagement';
import { SubscriptionProductLinkManagementModals } from 'src/subscriptionLinksManagement/utils/modals';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* createSubscriptionProductLinkSaga(action: CreateSubscriptionProductLinkAction) {
  try {
    const { payload } = action;
    const apiSubscriptionProduct: ApiSubscriptionProductLink = yield getContext(SagaContextItem.apiSubscriptionProduct);

    yield call(apiSubscriptionProduct.createSubscriptionProductLink, payload);

    const links = payload.links.map(it => ({
      id: it.id,
      subscriptionProduct: it.subscriptionProduct,
      deliveryProduct: it.deliveryProduct,
      status: it.status,
      metaInfo: it.metaInfo,
      regularProducts: it.regularProducts,
      countriesExclusion: it.countriesExclusion,
    } as SubscriptionProductLink));

    yield put(createSubscriptionProductLinkSuccess(links));
    yield put(closeModal(SubscriptionProductLinkManagementModals.createSubscriptionProductLink));
    yield put(showSuccessNotification('subscriptionProductLink.createSubscriptionProductLinkSaga.successNotificationHint'));

  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'oops... failed to create subscription product link',
      'hoppla... Beim Erstellen des Abonnement-Produktlinks ist ein Fehler aufgetreten.',
      err
    );
    yield put(createSubscriptionProductLinkFailed());
  }
}

export default function* createSubscriptionProductLinkWatcher() {
  yield takeLatest(createSubscriptionProductLinkAction.type, createSubscriptionProductLinkSaga);
}
