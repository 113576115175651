import {
  call,
  getContext,
  put,
  takeLatest
} from 'redux-saga/effects';

import { showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { ApiSubscriptionProductLink } from 'src/subscriptionLinksManagement/api/apiSubscriptionProduct';
import {
  editSubscriptionProductLinkAction,
  EditSubscriptionProductLinkAction,
  editSubscriptionProductLinkSuccess,
  editSubscriptionProductLinkFailed,
  closeModal
} from 'src/subscriptionLinksManagement/redux/subscriptionLinksManagementSlice';
import { SubscriptionProductLink } from 'src/subscriptionLinksManagement/types/subscriptionLinksManagement';
import { SubscriptionProductLinkManagementModals } from 'src/subscriptionLinksManagement/utils/modals';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* editSubscriptionProductLinkSaga(action: EditSubscriptionProductLinkAction) {
  try {
    const { payload } = action;
    const apiSubscriptionProduct: ApiSubscriptionProductLink = yield getContext(SagaContextItem.apiSubscriptionProduct);
    yield call(apiSubscriptionProduct.editSubscriptionProductLink, payload.id, payload);

    const builtLink: SubscriptionProductLink = {
      id: payload.id,
      subscriptionProduct: payload.subscriptionProduct,
      deliveryProduct: payload.deliveryProduct,
      status: payload.status,
      metaInfo: payload.metaInfo,
      regularProducts: payload.regularProducts,
      countriesExclusion: payload.countriesExclusion,
    };

    yield put(editSubscriptionProductLinkSuccess(builtLink));
    yield put(closeModal(SubscriptionProductLinkManagementModals.editSubscriptionProductLink));
    yield put(showSuccessNotification('subscriptionProductLink.editSubscriptionProductLinkSaga.successNotificationHint'));

  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'oops... failed to edit subscription product link',
      'hoppla... Beim Bearbeiten des Abonnement-Produktlinks ist ein Fehler aufgetreten.',
      err
    );
    yield put(editSubscriptionProductLinkFailed());
  }
}

export default function* editSubscriptionProductLinkWatcher() {
  yield takeLatest(editSubscriptionProductLinkAction.type, editSubscriptionProductLinkSaga);
}
