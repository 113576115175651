import {
  createAction,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { OverviewState, SubscriptionProductLink } from 'src/subscriptionLinksManagement/types/subscriptionLinksManagement';
import { EditSubscriptionProductLinkRequest, SubscriptionProductLinkRequestWrapper } from 'src/subscriptionLinksManagement/types/SubscriptionProductLinkRequest';
import { mockOverView } from 'src/subscriptionLinksManagement/utils/mockData';
import { SubscriptionProductLinkManagementModals } from 'src/subscriptionLinksManagement/utils/modals';
import { ProductDetails } from 'src/types/product/product';


export interface SubscriptionLinksManagementState {
  overview: OverviewState;
  editLink: EditLinkState;
  createLink: CreateLinkState;
  modals: SubscriptionProductLinkManagementModals[];
  bnrSearch: BNRSearch;
}

export interface CreateLinkState {
  loading: boolean;
  productData?: SubscriptionProductLink;
}
export interface EditLinkState {
  loading: boolean;
  linkData?: SubscriptionProductLink;
  productDetails?: ProductDetails;
}

export interface BNRSearch {
  loading: boolean;
  searchQuery?: string;
  productDetails?: ProductDetails;
}

export const initialSubscriptionLinksManagementState: SubscriptionLinksManagementState = {
  overview: {
    initialLoading: false,
    onScrollLoading: false,
    items: mockOverView,
    page: 1,
    total: 0,
    filters: {}
  },
  editLink: {
    loading: false
  },
  createLink: {
    loading: false
  },
  bnrSearch: {
    loading: false
  },
  modals: []
};

export const createSubscriptionProductLinkAction = createAction<SubscriptionProductLinkRequestWrapper>('subscriptionLinksManagement/CREATE_SUBSCRIPTION_PRODUCT_LINK');
export type CreateSubscriptionProductLinkAction = ReturnType<typeof createSubscriptionProductLinkAction>;

export const initSubscriptionProductLinkAction = createAction<SubscriptionProductLink>('subscriptionLinksManagement/INIT_SUBSCRIPTION_PRODUCT_LINK');
export type InitSubscriptionProductLinkAction = ReturnType<typeof initSubscriptionProductLinkAction>;

export const editSubscriptionProductLinkAction = createAction<EditSubscriptionProductLinkRequest>('subscriptionLinksManagement/EDIT_SUBSCRIPTION_PRODUCT_LINK');
export type EditSubscriptionProductLinkAction = ReturnType<typeof editSubscriptionProductLinkAction>;

export const searchByBnrAction = createAction<string>('subscriptionLinksManagement/SEARCH_BY_BNR');
export type SearchByBnrAction = ReturnType<typeof searchByBnrAction>;

const subscriptionLinksManagementSlice = createSlice({
  name: 'subscriptionLinksManagement',
  initialState: initialSubscriptionLinksManagementState,
  reducers: {
    createSubscriptionProductLinkSuccess(state,{ payload }: PayloadAction<SubscriptionProductLink[]>) {
      state.createLink.loading = false;
      state.overview.items = [...payload, ...state.overview.items];
    },
    createSubscriptionProductLinkFailed(state) {
      state.createLink.loading = false;
    },
    initSubscriptionProductLinkEdit(state, { payload }: PayloadAction<SubscriptionProductLink>) {
      state.editLink.linkData = payload;
    },
    initSubscriptionProductLinkEditSuccess(state, { payload }: PayloadAction<{linkData: SubscriptionProductLink, productDetails: ProductDetails}>) {
      state.editLink.linkData = payload.linkData;
      state.editLink.productDetails = payload.productDetails;
    },
    resetInitSubscriptionProductLinkEdit(state) {
      state.editLink = initialSubscriptionLinksManagementState.editLink;
    },
    editSubscriptionProductLinkSuccess(state,{ payload }: PayloadAction<SubscriptionProductLink>) {
      state.editLink.loading = false;
      state.overview.items = state.overview.items.map(item => item.id === payload.id ? payload : item);
    },
    editSubscriptionProductLinkFailed(state) {
      state.editLink.loading = false;
    },
    searchByBnrSuccess(state, { payload }: PayloadAction<ProductDetails>) {
      state.bnrSearch.productDetails = payload;
      state.bnrSearch.searchQuery = payload.baseProductNo;
      state.bnrSearch.loading = false;
    },
    resetSearchByBnr(state) {
      state.bnrSearch = initialSubscriptionLinksManagementState.bnrSearch;
    },
    openModal(state, { payload }: PayloadAction<SubscriptionProductLinkManagementModals>) {
      state.modals = [...state.modals, payload];
    },
    closeModal(state, { payload }: PayloadAction<SubscriptionProductLinkManagementModals>) {
      state.modals = state.modals.filter(it => it !== payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createSubscriptionProductLinkAction, state => {
        state.createLink.loading = true;
      })
      .addCase(editSubscriptionProductLinkAction, state => {
        state.editLink.loading = true;
      })
      .addCase(searchByBnrAction, state => {
        state.bnrSearch.loading = true;
      });
  }
});

export const {
  createSubscriptionProductLinkSuccess,
  createSubscriptionProductLinkFailed,
  initSubscriptionProductLinkEdit,
  editSubscriptionProductLinkSuccess,
  editSubscriptionProductLinkFailed,
  searchByBnrSuccess,
  resetSearchByBnr,
  openModal,
  closeModal,
  initSubscriptionProductLinkEditSuccess,
  resetInitSubscriptionProductLinkEdit
} = subscriptionLinksManagementSlice.actions;

export const subscriptionLinksManagementReducer = subscriptionLinksManagementSlice.reducer;
