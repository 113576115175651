import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiOrderHistory } from 'src/api/apiOrderHistory';
import { logErrorEvent } from 'src/logging/loggingActions';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { fetchCustomer, setPostCustomerAuthCheckData } from 'src/redux/customer/customerInfoSlice';
import { fetchByLimelightOrderId } from 'src/redux/customer/orderHistorySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';


export function* fetchOrderInfoByLimelightOrderIdSaga(action: PayloadAction<string>) {
  const payload = action?.payload;
  try {
    const api: ApiOrderHistory = yield getContext(SagaContextItem.apiOrderHistory);
    const searchOrderResult = yield call(api.getExternalCustomerIdByLimelightOrderId, payload);
    yield put(setPostCustomerAuthCheckData({ openOrdersHistory: { limelightOrderId: payload } }));
    yield put(fetchCustomer(searchOrderResult.externalCustomerId));
  } catch (err) {
    if (isNotFoundStatus(err)) {
      yield put(
        logErrorEvent({ message: `Failed to external_customer_id for order ${payload}`, err }),
      );
    }
    yield put(showErrorNotification('orderHistory.fetchByLimelightId.failed'));
  }
}

export function* fetchOrderInfoByLimelightOrderIdWatcher() {
  yield takeLatest(fetchByLimelightOrderId.type, fetchOrderInfoByLimelightOrderIdSaga);
}
