import {
  call,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import { ApiProduct } from 'src/api/apiProduct';
import { logErrorEvent } from 'src/logging/loggingActions';
import {
  storeSelectedDeliveryAddressOrUseBilling,
  fetchCustomer,
  setPostCustomerAuthCheckData
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import {
  setGlobalLoadingFlag,
  LoadReklaOrder,
  loadReklaOrder,
  addOrderItems,
  setItemError
} from 'src/redux/order/orderEntrySlice';
import { setSelectedPaymentMethod, storeSelectedPaymentMethod } from 'src/redux/payment/paymentSlice';
import { fetchProduct } from 'src/redux/product/productDetailsSlice';
import { processAndGetOffer } from 'src/sagas/offer/getOffer';
import {
  AddItemToBasketResult,
  AddItemToBasketStatus,
  addItemToBasket
} from 'src/sagas/orderEntry/addItemToBasketService';
import { deleteCurrentBasket } from 'src/sagas/orderEntry/deleteCurrentBasket';
import getProductBySku from 'src/sagas/product/productService';
import { loadExistingOrderCustomer } from 'src/sagas/recreateOrder/fillOriginalOrderCustomer';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { DeliveryAddress } from 'src/types/customer/address';
import { StockLevel, Variant } from 'src/types/offer/Basket';
import { BasketItemRequest } from 'src/types/offer/BasketItemRequest';
import {
  NotSellableOrderItem,
  NotSellableOrderItemReason,
  OrderItem
} from 'src/types/offer/OrderItem';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { OrderHistoryItem } from 'src/types/orderhistory/OrderHistoryResponse';
import { ProductDetails } from 'src/types/product/product';
import { StockResponse } from 'src/types/product/StockResponse';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';


export function* loadReklaOrderSaga(action: LoadReklaOrder) {
  try {
    const {
      paymentMethod,
      deliveryAddress,
      externalCustomerId,
      item,
    } = action.payload;

    const customer = yield select(customerInfoSelectors.getCustomer);

    if (!customer) {
      yield put(logErrorEvent({ message: 'Cannot load rekla order without customer, trying to load customer...' }));
      if (externalCustomerId) {
        yield put(setPostCustomerAuthCheckData({ reklaOrder: action.payload }));
        yield put(fetchCustomer(externalCustomerId));
      }
      return;
    }

    yield call(addOrderItemToBasket, item);
    if (externalCustomerId) {
      yield call(loadExistingOrderCustomer, externalCustomerId, true);
    }
    if (deliveryAddress) {
      yield call(fillDeliveryAddress, deliveryAddress);
    }
    if (paymentMethod) {
      yield call(fillPaymentMethod, paymentMethod);
    }
    yield put(setGlobalLoadingFlag(true));
  } catch (err) {
    yield put(logErrorEvent({ message: 'Load rekla order: Could not load the rekla order ' }));
  } finally {
    yield put(setGlobalLoadingFlag(false));
  }
}

function* addOrderItemToBasket(item: OrderHistoryItem) {
  try {
    const sku = item.sku;
    const baseProductNumber = item.baseProductNumber ?? toBaseProductNumber(item.sku);
    yield call(deleteCurrentBasket);
    const basketItemRequest: BasketItemRequest = {
      baseProductNo: baseProductNumber,
      sku: sku,
      quantity: 1,
      priceDate: item.priceDate
    };
    const result: AddItemToBasketResult = yield call(addItemToBasket, basketItemRequest);
    if (result.status === AddItemToBasketStatus.RESERVATION_FAILURE) {
      const isCompletelySoldOut: boolean = yield call(areAllVariantsSoldOut, baseProductNumber);
      if (isCompletelySoldOut) {
        yield call(displaySoldOutItemInBasket, item, sku, null);
      } else {
        yield put( fetchProduct({ sku }));
      }
    } else if (result.status === AddItemToBasketStatus.INVALID_VARIANT_SKU){
      yield call(displaySoldOutItemInBasket, item, sku, item.baseProductNumber);
    }
    yield call(processAndGetOffer);
  } catch {
    yield put(logErrorEvent({ message: 'Load rekla order: Could not add rekla order item to basket' }));
  }
}

function* displaySoldOutItemInBasket(item: OrderHistoryItem, sku: string, baseProductNumber: string | null) {
  const product: ProductDetails | undefined  = yield call(getProductBySku, baseProductNumber ?? sku);
  const variant = product?.variants.find(it => it.sku === baseProductNumber ? product?.defaultVariantSku : sku);
  const orderLineItem = {
    id: item.id,
    baseProductNo: product?.baseProductNo,
    stockLevel: baseProductNumber ? StockLevel.notSellable : StockLevel.soldOut,
    name: product?.name,
    brand: product?.brand,
    quantity: item.quantity,
    isSellable: false,
    variant: {
      dimensions: item.variants,
      price: variant?.price,
      sku: baseProductNumber ?? sku,
      images: item.mediaUris,
      deliveryTime: item.deliveryTime
    } as Variant,
    status: {
      type: 'NOT_SELLABLE',
      reason: baseProductNumber ? NotSellableOrderItemReason.VARIANT_NOT_AVAILABLE : NotSellableOrderItemReason.OUT_OF_STOCK
    } as NotSellableOrderItem
  } as OrderItem;

  yield put(addOrderItems([orderLineItem]));
  yield put(setItemError({ id: item.id, error: { field: 'stock' } }));
}

function* areAllVariantsSoldOut(baseProductNo: string) {
  const apiProduct: ApiProduct = yield getContext(SagaContextItem.apiProduct);
  const stock: StockResponse = yield call(apiProduct.getProductsStock, [baseProductNo]);
  return stock.stock.filter(it => it.amount > 0).length === 0;
}

function* fillDeliveryAddress(address: DeliveryAddress) {
  try {
    const addresses: DeliveryAddress[] = yield select(customerInfoSelectors.getDeliverAddresses);
    const originalAddress = addresses.find(it => it.externalAddressId === address.externalAddressId);
    yield put(storeSelectedDeliveryAddressOrUseBilling(originalAddress));
    yield call(processAndGetOffer);
  } catch {
    yield put(logErrorEvent({ message: 'Load rekla order: Could not pre-select delivery address' }));
  }
}

function* fillPaymentMethod(paymentMethod: PaymentMethod) {
  try {
    yield put(storeSelectedPaymentMethod(paymentMethod));
    yield put(setSelectedPaymentMethod(paymentMethod));
    yield call(processAndGetOffer);
  } catch {
    yield put(logErrorEvent({ message: 'Load rekla order: Could not pre-select payment method' }));
  }
}

export default function* loadReklaOrderWatcher() {
  yield takeLatest(loadReklaOrder.type, loadReklaOrderSaga);
}
