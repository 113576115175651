import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import getProductBySku from 'src/sagas/product/productService';
import {
  InitSubscriptionProductLinkAction,
  initSubscriptionProductLinkEdit,
  initSubscriptionProductLinkEditSuccess,
  openModal
} from 'src/subscriptionLinksManagement/redux/subscriptionLinksManagementSlice';
import { SubscriptionProductLinkManagementModals } from 'src/subscriptionLinksManagement/utils/modals';
import { ProductDetails } from 'src/types/product/product';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


function* initEditSubscriptionProductLinkSaga(action: InitSubscriptionProductLinkAction) {
  try {
    const { payload } = action;
    const response: ProductDetails = yield call(getProductBySku, payload.subscriptionProduct.number.replace(/ /g, ''));
    yield put(initSubscriptionProductLinkEditSuccess({ 
      linkData: payload, 
      productDetails: response
    }));
    yield put(openModal(SubscriptionProductLinkManagementModals.editSubscriptionProductLink));
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'oops... failed to initiate subscription link edit.',
      'hoppla... Beim Bearbeiten des Abonnement-Links ist ein Fehler aufgetreten.',
      err
    );
    
  }
}

export default function* initEditSubscriptionProductLinkWatcher() {
  yield takeLatest(initSubscriptionProductLinkEdit.type, initEditSubscriptionProductLinkSaga);
}