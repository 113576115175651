import { createSelector } from '@reduxjs/toolkit';

import { RootStateType } from 'src/constants/types';
import { newOrderSelector } from 'src/redux/order/selectors/newOrderSelectors';
import { DeliveryAddressType } from 'src/types/customer/address';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';
import { CustomerType } from 'src/types/voucher/EmployeeDiscount';
import { createSelectors } from 'src/utils/state';
import { isCustomerLocked as canCustomerOrder } from 'src/utils/validators/isCustomerLocked';


export const customerInfoSelectors = createSelectors({
  hasCustomer({ customer: { info } }: RootStateType) {
    return !!info.customerResponse;
  },
  isExistingCustomer({ customer: { info } }: RootStateType) {
    return !!info?.customerResponse?.externalCustomerId;
  },
  hasSelectedDefaultPaymentMethod(state: RootStateType) {
    return !!state.payment.selectedPaymentMethod;
  },
  getCustomerInfo(state: RootStateType) {
    return state.customer.info;
  },
  customerNumberField(state: RootStateType) {
    return state.customer.info.customerNumberField;
  },
  getCustomerExternalId({ customer: { info } }: RootStateType) {
    return info?.customerResponse?.externalCustomerId;
  },
  getCustomerId({ customer: { info } }: RootStateType) {
    return info?.customerResponse?.id;
  },
  isCustomerLocked(state: RootStateType) {
    return canCustomerOrder(state?.customer.info?.customerResponse);
  },
  isCustomerHasEmail({ customer: { info } }: RootStateType) {
    return !!info.customerResponse?.email;
  },
  getCustomer({ customer: { info } }: RootStateType) {
    return info.customerResponse;
  },
  getAdditionalDeliveryAddress({ customer: { info } }: RootStateType) {
    return info.additionalDeliveryAddress;
  },
  getDeliverAddresses({ customer: { info } }: RootStateType) {
    return info.customerResponse?.deliveryAddresses || [];
  },
  getSelectedDeliveryAddress({ customer: { info } }: RootStateType) {
    return info.selectedDeliveryAddress;
  },
  billingAsDeliveryAddress(state: RootStateType) {
    const selectedAddress = state.customer.info.selectedDeliveryAddress;
    return state.customer.info.billingAsDeliveryAddress ||
      selectedAddress?.type === DeliveryAddressType.Postal && selectedAddress.fromInvoiceAddress;
  },

  getCustomerBillingAddress(state: RootStateType) {
    return (state.customer.info.customerResponse?.deliveryAddresses || []).find(address => address.type === DeliveryAddressType.Postal && address.fromInvoiceAddress);
  },

  getCustomerSearchData({ customer: { info } }: RootStateType) {
    return info.customerSearchData;
  },
  billingAddressValidated({ customer: { info } }: RootStateType) {
    return !!info.billingAddressValidated;
  },
  deliveryAddressValidated({ customer: { info } }: RootStateType) {
    return !!info.deliveryAddressValidated;
  },
  getCustomerPhoneNumber({ customer: { info } }: RootStateType) {
    return info.customerResponse?.phoneNumber;
  },
  getCustomerOrderRestriction({ customer: { info } }: RootStateType) {
    return info.customerOrderRestriction;
  },
  isCustomerHSEEmployee({ customer: { info } }: RootStateType) {
    return (
      !!info.customerResponse?.type &&
      ![CustomerType.REGULAR, CustomerType.TV_CREATOR, CustomerType.INFLUENCER].includes(
        info.customerResponse?.type,
      )
    );
  },
  getCustomerType({ customer: { info } }: RootStateType) {
    return info.customerResponse?.type;
  },
  getCustomerAvailablePaymentMethods({ customer: { info } }: RootStateType) {
    return info.availablePaymentMethods;
  },
  getCustomerSuggestionLoading({ customer }: RootStateType) {
    return customer.suggestions.loading;
  },
  upsellsDisallowed({ customer: { info } }: RootStateType) {
    return info.customerResponse?.allowUpsells === false;
  },
  isConsentEmailNewsLetter({ customer: { info } }: RootStateType) {
    return info.customerResponse?.consents?.emailNewsletter;
  },
  isThereACustomerSearchInProgress({ customer: { info } }: RootStateType) {
    return info.loading;
  },
  getBlockedCustomer({ customer: { info } }: RootStateType) {
    return info.blockedCustomer;
  },
  isExEmployeeCustomer({ customer: { info } }: RootStateType) {
    return info.customerOrderRestriction === OrderRestriction.departedEmployee;
  },
  isDuplicateCustomer({ customer: { info } }: RootStateType) {
    return info.customerOrderRestriction === OrderRestriction.duplicate;
  },
  isDeletionGDPR({ customer: { info } }: RootStateType) {
    return info.customerOrderRestriction === OrderRestriction.deletionGdpr;
  },
  getSelectedCustomerLocalId(state: RootStateType): string | undefined {
    return state.customer.info.selectedLocalCustomerId;
  },
  getBillingAddress({ customer: { info } }: RootStateType) {
    return info.customerResponse?.billingAddress;
  },

  getBlockedDeliveryAddresses({ customer: { info } }: RootStateType) {
    return info.blockedDeliveryAddresses;
  },
  hasAddress({ customer: { info } }: RootStateType) {
    return (
      (info.billingAsDeliveryAddress && info.customerResponse?.billingAddress) ||
      info.selectedDeliveryAddress
    );
  },
  isSendingRingTemplateLoading({ customer: { info } }: RootStateType) {
    return info.infoPost.ringsTemplate.loading;
  },
  isSendingRingTemplateSuccessful({ customer: { info } }: RootStateType) {
    return info.infoPost.ringsTemplate.letterSent;
  },
  isCreateDeliveryAddressAllowed({ customer: { info } }: RootStateType) {
    return info.customerResponse?.isCreateDeliveryAddressAllowed || !info.customerResponse?.externalCustomerId;
  },
  canAddNewDeliverAddress(state: RootStateType) {
    const customer = state.customer.info.customerResponse;
    return (customer?.isCreateDeliveryAddressAllowed || !customer?.externalCustomerId) &&
      !customer?.deliveryAddresses?.find(a => !a.externalAddressId && !(a.type === DeliveryAddressType.Postal && a.fromInvoiceAddress));
  },
  getPreferredPayment(state: RootStateType) {
    return state.customer.info.customerResponse?.preferredPaymentMethod;
  },
  getSelectedPreferredPayment(state: RootStateType) {
    return state.customer.info.selectedPreferredPayment;
  },
  getPostCustomerAuthCheckData(state: RootStateType) {
    return state.customer.info.postCustomerAuthCheckData;
  },
  getDhlPostNumberState(state: RootStateType) {
    return state.customer.info.dhlPostNumberVerificationState;
  }
});

export const isPhoneNumberRequired = createSelector(
  newOrderSelector.hasTelephoneNotification,
  customerInfoSelectors.getCustomerPhoneNumber,
  (isTelephoneNotification, customerPhoneNumber) => {
    return isTelephoneNotification && !customerPhoneNumber;
  },
);
