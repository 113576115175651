import { CountryCode } from 'src/api/orderTypes';
import { SubscriptionProductLink } from 'src/subscriptionLinksManagement/types/subscriptionLinksManagement';
import { Currency } from 'src/types/product/Currency';
import { ShippingType } from 'src/types/product/DeliveryTime';
import { DimensionType } from 'src/types/product/dimensions';
import { ProductDetails, ProductStatus } from 'src/types/product/product';


export const mockProductDataSingleVariant: ProductDetails = {
  baseProductNo: '466620',
  defaultVariantSku: '466620',
  name: {
    short: 'Eiweißlinge für 12 Monate',
    long: 'Eiweißlinge für 12 Monate',
  },
  description:
    '<p>Die Eiweißlinge sind ein wertvolles Nahrungsergänzungsmittel mit Aminosäuren als Eiweißbausteinen. Sie erhalten hier Eiweißlinge für zwölf Monate, das sind 4.320 Stück.</p><p><b>Fazit: Mehr kaufen und dabei viel sparen.</b></p><h3><b>Reinheitsgebot von Nicola Sautter</b></h3><p>Das Reinheitsgebot entspricht der persönlichen Überzeugung von Nicola Sautter. Die genannten Kriterien berücksichtigt Frau Sautter sowohl als Privatperson in ihrer Ernährung als auch als Heilpraktikerin in ihrer Praxis. Alle Markenartikel werden nach folgenden Grundsätzen produziert:</p><ul><li>vegan</li><li>glutenfrei</li><li>laktosefrei</li><li>frei von Sojaeiweißen</li><li>frei von künstlichen Süßungsmitteln</li><li>frei von künstlichen Farbstoffen</li><li>frei von Konservierungsstoffen</li><li>frei von Geschmacksverstärkern</li><li>frei von Lupinen</li><li>hergestellt in Deutschland</li></ul><p>Die Verkehrsfähigkeit und Qualität werden vom Institut Prof. Dr. Kurz überwacht und mit einem Gütesiegel belegt. Mit dem Qualitätssiegel wird geprüft und sichergestellt, dass</p><ul><li>Drin ist, was drauf steht (Analyse). Die Angaben der Inhaltstoffe (Rohstoffart und Grammatur) auf dem Etikett des Artikels entsprechen dem Inhalt.</li></ul><ul><li>Nicht drin ist, was nicht hinein gehört (Mikrobiologische Beschaffenheit). Prüfung auf krankheitsauslösende Erreger, Rückstandskontrolle für Schwermetalle (Arsen, Blei, Cadmium, Quecksilber usw.) sowie auf Pestizide.</li></ul><p><b>Bestellen Sie gleich hier ganz bequem im Onlineshop.</b></p><p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:11.0pt;font-family:Calibri,sans-serif;"><span style="font-size:12.0pt; font-family:Times New Roman,serif;">Sie erhalten dieses Produkt auch ganz bequem im günstigen Treue Abo in einem frei wählbaren Lieferzyklus. Wenden Sie sich bei Interesse bitte an unsere gebührenfreie Bestell-Hotline <b>0800 29 888 88</b>.</span></p>',
  additionalInformation:
    '<table border="0" class="pt" cellpadding="0" cellspacing="0"><tr><td valign="top" class="sL">Lieferumfang:</td><td class="sM"><br /></td><td valign="top" class="sR"><p>Nicola Sautter Eiweißlinge<br />für 12 Monate<br />das sind insgesamt 4.320 Stück</p><p>mit Eiweißflyer</p></td></tr></table>',
  ingredients:
    'ZUTATEN PRO 100 G: L-LEUCIN 17,35 G, L-PHENYLALANIN 15,82 G, L-VALIN 12,1 G, L-ISOLEUCIN 12,04 G, L-LYSIN 9,68 G, DI CALCIUMPHOSPHAT 8,24 G,L-THREONIN 7,86 G, MIKROKRISTALLINE CELLULOSE 7,8 G, L-METHIONIN 7,31 G, L-TRYPTOPHAN 1,89 G',
  headline: 'Eiweißlinge von Nicola Sautter - Nahrungsergänzung mit Aminosäuren',
  legalInformation: {
    uri: '/de/products/466/466620/466620_01_pflichtinformation.pdf',
    mediaType: 'document',
  },
  status: ProductStatus.Sellable,
  media: [
    {
      uri: '/466620/60065415_5a51b519-9f08-11ee-99f7-5a72d431453d',
      mediaType: 'image',
    },
  ],
  usps: [
    'insgesamt 4.320 Eiweißlinge',
    'Aminosäuren als Eiweißbausteine',
    '100 % vegan, gluten- und lactosefrei',
  ],
  brand: {
    brandName: 'Nicola Sautter',
    brandIcon: {
      uri: '/de/brands/18110884/18110884_01_brand-logos.png',
      mediaType: 'image',
    },
  },
  variants: [
    {
      sku: '466620',
      status: ProductStatus.Sellable,
      price: {
        countryCode: 'DE',
        currencyCode: Currency.euro,
        label: 'Angebot des Monats',
        value: 599,
        validFrom: '2024-12-25T00:00:00+01:00',
        validTo: '2025-01-31T23:59:59+01:00',
        reference: {
          label: 'HSE Preis',
          saving: 270,
          savingPercent: 31,
          value: 869,
          type: 'B',
        },
        base: {
          amount: 1,
          unit: 'kg',
          value: 184.877,
        },
      },
      deliveryTime: {
        deliveryDays: 5,
        deliveryMinDays: 3,
        shippingType: ShippingType.standard,
        deliveryMessage: 'Lieferung innerhalb von 3-5 Werktagen',
      },
      isSellable: true,
    },
  ],
  salesdrivers: [
    {
      typeCode: 'EDM',
      name: 'Angebot des Monats',
      validFrom: '2024-12-25T00:00:00+01:00',
      validTo: '2025-02-01T00:00:00+01:00',
    },
  ],
  seo: {
    description:
      'Nicola Sautter Eiweißlinge zur Nahrungsergänzung für 12 Monate jetzt bequem online bei HSE.de bestellen.',
    title: 'Nicola Sautter Eiweißlinge - online bei HSE.de',
  },
  created: '2023-09-09T02:13:04+02:00',
  newInShop: '2023-12-20T00:00:00+01:00',
  countryVisibilities: [CountryCode.de, CountryCode.at],
};

export const mockProductDataWithVariants: ProductDetails = {
  baseProductNo: '467360',
  defaultVariantSku: '467360001',
  name: {
    short: 'Schüssel-Set mit Tragegriff, 2tlg.',
    long: 'Schlüssel-Set mit Tragegriff, 2tlg.'
  },
  description: '',
  additionalInformation: '',
  headline: 'Praktische Glasdosen für Vorratshaltung & Transport',
  status: ProductStatus.Sellable,
  media: [
    { uri: '/467360/59978852_86805b8e-0d33-11ef-9ba3-5a72d431453d', mediaType: 'image' },
    { uri: '/467360/59969853_5e3c5d06-eccd-11ee-8cfd-5a72d431453d', mediaType: 'image' },
    { uri: '/467360/59969859_618ae2f7-eccd-11ee-8cfd-5a72d431453d', mediaType: 'image' }
  ],
  usps: [
    '6L-Dose mit Griff / 1L-Dose ohne Griff',
    'aus bruchfestem Hartglas',
    'mikrowellen-, spülmaschinen- & gefrierfest'
  ],
  dimensions: [
    {
      type: DimensionType.COLOR,
      values: [
        { value: 'dunkelblau', codeHex: '027DA9' },
        { value: 'magenta', codeHex: 'CF5562' },
        { value: 'eisblau', codeHex: '94E8EA' },
        { value: 'pflaume', codeHex: '71637C' },
        { value: 'koralle', codeHex: 'FFB7AE' }
      ]
    }
  ],
  imageRelevantDimension: DimensionType.COLOR,
  brand: {
    brandName: 'Glasslock',
    brandIcon: {
      uri: '/de/brands/18277234/18277234_01_brand-logos.png',
      mediaType: 'image'
    },
  },
  variants: [
    {
      sku: '467360005',
      status: ProductStatus.Sellable,
      price: { countryCode: 'DE', currencyCode: Currency.euro, label: 'Bestseller', value: 19.99 },
      deliveryTime: { deliveryDays: 5, deliveryMinDays: 3, shippingType: ShippingType.standard, deliveryMessage: 'Lieferung innerhalb von 3-5 Werktagen' },
      dimensions: [{ type: DimensionType.COLOR, value: 'pflaume' }],
      images: [{ uri: '/467360005/60031043_6703e986-74f0-11ef-8dec-5a72d431453d', mediaType: 'image' }],
      isSellable: true
    },
    {
      sku: '467360001',
      status: ProductStatus.Sellable,
      price: { countryCode: 'DE', currencyCode: Currency.euro, label: 'Bestseller', value: 19.99 },
      deliveryTime: { deliveryDays: 5, deliveryMinDays: 3, shippingType: ShippingType.standard, deliveryMessage: 'Lieferung innerhalb von 3-5 Werktagen' },
      dimensions: [{ type: DimensionType.COLOR, value: 'dunkelblau' }],
      images: [{ uri: '/467360001/59969854_5ec7bb43-eccd-11ee-8cfd-5a72d431453d', mediaType: 'image' }],
      isSellable: true
    },
    {
      sku: '467360002',
      status: ProductStatus.Sellable,
      price: { countryCode: 'DE', currencyCode: Currency.euro, label: 'Bestseller', value: 19.99 },
      deliveryTime: { deliveryDays: 5, deliveryMinDays: 3, shippingType: ShippingType.standard, deliveryMessage: 'Lieferung innerhalb von 3-5 Werktagen' },
      dimensions: [{ type: DimensionType.COLOR, value: 'eisblau' }],
      images: [{ uri: '/467360002/59969855_5f574798-eccd-11ee-8cfd-5a72d431453d', mediaType: 'image' }],
      isSellable: true
    },
    {
      sku: '467360003',
      status: ProductStatus.Sellable,
      price: { countryCode: 'DE', currencyCode: Currency.euro, label: 'Bestseller', value: 19.99 },
      deliveryTime: { deliveryDays: 5, deliveryMinDays: 3, shippingType: ShippingType.standard, deliveryMessage: 'Lieferung innerhalb von 3-5 Werktagen' },
      dimensions: [{ type: DimensionType.COLOR, value: 'magenta' }],
      images: [{ uri: '/467360003/59969858_60fe8324-eccd-11ee-8cfd-5a72d431453d', mediaType: 'image' }],
      isSellable: true
    },
  ],
  seo: {
    description: 'Das 2teilige Schüsselset mit Tragegriff und andere Behälter von Glasslock finden Sie online bei HSE.de',
    title: 'Glasslock Schüsselset 2tlg mit Tragegriff online'
  },
  created: '2023-11-14T02:13:10+01:00',
  newInShop: '2024-05-31T00:00:00+02:00',
  countryVisibilities: [CountryCode.de, CountryCode.at],
};

export const productLinkData: SubscriptionProductLink = {
  id: '0000-0000-0000-0000-0000',
  deliveryProduct: {
    number: '123456',
    name: 'Schlüssel-Set mit Tragegriff, 2tlg.',
    price: 19.99 
  },
  subscriptionProduct: {
    number: '467360',
    name: 'Schlüssel-Set mit Tragegriff, 2tlg.',
  },
  status: 'EXPIRED',
  metaInfo: {
    createdBy: 'BANANA',
    createdAt: '30.08.2024'
  },
  countriesExclusion: [CountryCode.at],
  regularProducts: ['000000', '111111']
};

export const mockOverView = [productLinkData, productLinkData];

export const mockProductData = mockProductDataWithVariants;