import months from 'src/subscriptionProductManagement/messages/months.de';
import subscriptionProductReports from 'src/subscriptionProductManagement/messages/subscriptionProductsReport.de';


export default {
  ...subscriptionProductReports,
  ...months,

  'subscriptionManagement.tabs.ABO_OVERVIEW': 'Treue Abo Abonnements',
  'subscriptionManagement.tabs.ABO_PRODUCT': 'Treue Abo Produkte',
  'subscriptionManagement.tabs.ABO_PRODUCT_MANAGEMENT': 'Abo Pflege',

  'subscriptionManagement.statusFilters.ALL': 'All Abos',
  'subscriptionManagement.statusFilters.ACTIVE': 'Aktiv',
  'subscriptionManagement.statusFilters.IN_PROGRESS': 'in Arbeit',
  'subscriptionManagement.statusFilters.DELAY_IN_DELIVERY': 'Lieferverzug',
  'subscriptionManagement.statusFilters.CANCELED': 'gekündigt',

  'subscriptionManagement.datesFilter': 'Datum-Filter:',
  'subscriptionManagement.cancellationReasonFilter': 'Stornogrund-Filter:',

  'subscriptionManagement.dateParameterFilter.NONE': 'Filter auswählen',
  'subscriptionManagement.dateParameterFilter.CREATION_DATE': 'Erfassungsdatum',
  'subscriptionManagement.dateParameterFilter.NEXT_DELIVERY_DATE': 'Nächste Auftragsanlage',
  'subscriptionManagement.dateParameterFilter.PREVIOUS_EDIT_DATE': 'Zuletzt bearbeitet',

  'subscriptionManagement.periodFilters.YESTERDAY': 'Gestern',
  'subscriptionManagement.periodFilters.LAST_WEEK': 'Letzte Woche',
  'subscriptionManagement.periodFilters.LAST_MONTH': 'Letzter Monat',
  'subscriptionManagement.periodFilters.LAST_SIX_MONTHS': 'Letzten 6 Monate',
  'subscriptionManagement.periodFilters.LAST_YEAR': 'Letztes Jahr',
  'subscriptionManagement.periodFilters.NEXT_WEEK': 'Nächste Woche',
  'subscriptionManagement.periodFilters.NEXT_MONTH': 'Nächster Monat',
  'subscriptionManagement.periodFilters.CUSTOM': 'Datum eingeben',

  'subscriptionManagement.productFilters.DELIVERY_PRODUCT_NO': 'Produkt',
  'subscriptionManagement.productFilters.SUBSCRIPTION_NO': 'Abo Nr.',
  'subscriptionManagement.productFilters.CUSTOMER_NO': 'Kundennummer',
  'subscriptionManagement.productFilters.searchPlaceHolder.DELIVERY_PRODUCT_NO': 'Artikelbezeichnung/BNR',
  'subscriptionManagement.productFilters.searchPlaceHolder.SUBSCRIPTION_NO': 'Abo Nr.',
  'subscriptionManagement.productFilters.searchPlaceHolder.CUSTOMER_NO': 'Kundennummer',
  'subscriptionManagement.selectAll': 'Alle auswählen',
  'subscriptionManagement.selected': 'Ausgewählt',
  'subscriptionManagement.editDeliveryDate': 'Lieferdatum ändern',
  'subscriptionManagement.editProductNumber': 'Ersetzen Werbe BNR',
  'subscriptionManagement.copyCustomerData': 'Kundendaten kopieren',
  'subscriptionManagement.copyCustomerData.success': 'Kundendaten erfolgreich kopiert',
  'subscriptionManagement.copyCustomerData.failed': 'Kundendaten konnten nicht kopiert werden',
  'subscriptionManagement.cancelSubscriptions': 'Abonnements kündigen',

  'subscriptionManagement.table.status.ACTIVE': 'Aktiv',
  'subscriptionManagement.table.status.CANCELED': 'Gekündigt',
  'subscriptionManagement.table.status.CREATED': 'Erstellt',
  'subscriptionManagement.table.status.FAILED': 'Fehlgeschlagen',
  'subscriptionManagement.table.status.IN_PROGRESS': 'in Arbeit',
  'subscriptionManagement.table.status.DELAYED_IN_DELIVERY': 'Lieferverzug',

  'subscriptionManagement.table.headers.aboNbr': 'Abo Nr.',
  'subscriptionManagement.table.headers.aboProductNumber': 'Werbe BNR',
  'subscriptionManagement.table.headers.baseProductNumber': 'Liefer BNR',
  'subscriptionManagement.table.headers.country': 'Land',
  'subscriptionManagement.table.headers.rotation': 'Turnus',
  'subscriptionManagement.table.headers.productName': 'Artikelbezeichnung',
  'subscriptionManagement.table.headers.price': 'Preis',
  'subscriptionManagement.table.headers.previousOrder': 'Vorherige Auftragsanlage',
  'subscriptionManagement.table.headers.nextOrder': 'Nächste Auftragsanlage',
  'subscriptionManagement.table.headers.deliveryEdited': 'Auftragsanlage bearbeitet',
  'subscriptionManagement.table.headers.status': 'Status',
  'subscriptionManagement.table.rowActions.edit': 'Bearbeiten',
  'subscriptionManagement.table.rowActions.cancel': 'Stornieren',
  'subscriptionManagement.table.rowActions.editProductNumber': 'Ersetzen Werbe BNR',

  'subscriptionManagement.productReport.headers.deliveryProductNo': 'Liefer BNR',
  'subscriptionManagement.productReport.headers.subscriptionProductNo': 'Werbe BNR',
  'subscriptionManagement.productReport.headers.productName': 'Artikelbezeichnung',
  'subscriptionManagement.productReport.headers.price': 'Einzelpreis',
  'subscriptionManagement.productReport.headers.subscriptionsCount': 'Anzahl Abonnenten',
  'subscriptionManagement.productReport.headers.currentStock': 'Bestand',
  'subscriptionManagement.productReport.headers.available': 'Vorrätig',

  'subscriptionManagement.rotation.ONE_MONTH': 'Monatlich',
  'subscriptionManagement.rotation.TWO_MONTHS': '2-monatlich',
  'subscriptionManagement.rotation.THREE_MONTHS': '3-monatlich',
  'subscriptionManagement.rotation.FOUR_MONTHS': '4-monatlich',
  'subscriptionManagement.rotation.FIVE_MONTHS': '5-monatlich',
  'subscriptionManagement.rotation.SIX_MONTHS': '6-monatlich',

  'subscriptionManagement.subscriptionNote.titleProductName': 'Notizen: {productName}',
  'subscriptionManagement.subscriptionNote.save': 'Speichern',
  'subscriptionManagement.subscriptionNote.close': 'Abbrechen',

  'subscriptionProductManagement.subscriptionCanceled':
    'Die Stornierung wird bearbeitet. Es dauert einen Moment.',

  'subscriptionManagement.editDeliveryDate.title': 'Liefertermin bearbeiten',
  'subscriptionManagement.editProductNumber.title': 'Werbe BNR bearbeiten',
  'subscriptionManagement.editDeliveryDate.reason': 'Soll das nächste Lieferdatum geändert werden?',
  'subscriptionManagement.editProductNumber.reason': 'Soll das Ersetzen Werbe BNR geändert werden?',
  'subscriptionManagement.editDeliveryDate.closeButton': 'Abbrechen',
  'subscriptionManagement.editProductNumber.closeButton': 'Abbrechen',
  'subscriptionManagement.editDeliveryDate.submitButton': 'Speichern und weiter',
  'subscriptionManagement.editProductNumber.submitButton': 'Speichern und weiter',
  'subscriptionManagement.editDeliveryDate.week.option': '1 Woche',
  'subscriptionManagement.editDeliveryDate.month.option': '1 Monat',
  'subscriptionManagement.editDeliveryDate.skip.option': 'Nächste Lieferung aussetzen',
  'subscriptionManagement.editDeliveryDate.error':
    'Überspringen Hervorheben und Fehler angeben, z. B. Neues Datumsfeld',
  'subscriptionManagement.editDeliveryDate.addNote': 'Notiz hinzufügen (optional)',
  'subscriptionManagement.editDeliveryDate.newDate': 'Neues Lieferdatum',
  'subscriptionManagement.editDeliveryDate.currentDate': 'Aktuelles Lieferdatum',
  'subscriptionManagement.editProductNumber.currentSubscriptionNumber': 'Aktuelles Werbe BNR',
  'subscriptionManagement.editProductNumber.newSubscriptionNumber': 'Neues Werbe BNR',
  'subscriptionManagement.editProductNumber.currentDeliveryNumber': 'Aktuelles Liefer BNR',
  'subscriptionManagement.editProductNumber.newDeliveryNumber': 'Neues Liefer BNR',
  'subscriptionManagement.editDeliveryDate.multipleSubscriptions.successNotificationHint': 'Lieferdatum für ausgewählte Bestellung(en) erfolgreich angepasst',
  'subscriptionManagement.editDeliveryDate.alreadyProcessedSubscriptions': 'Abonnements wurden bereits bearbeitet...',
  'subscriptionManagement.editDeliveryDate.multipleSubscriptions.unsuccessfulNotificationHint': 'Anpassung Lieferdatum für ausgewählte Bestellung(en) fehlgeschlagen',
  'subscriptionManagement.editDeliveryDate.oneSubscription.successNotificationHint': 'Lieferdatum wurde erfolgreich angepasst',

  'subscriptionProductManagement.subscriptionDetailsView.backToOverview':
    'Zurück zu Treue Abo Abonnements',
  'subscriptionProductManagement.subscriptionDetails.fullName': 'Vollständiger Name',
  'subscriptionProductManagement.subscriptionDetails.completedOrders': 'Erfolgte Aufträge',
  'subscriptionProductManagement.subscriptionDetails.deliveryDetails':
    'Details zur Auftragsanlage:',
  'subscriptionProductManagement.subscriptionDetails.lastEditDate': 'Zuletzt bearbeitet',
  'subscriptionProductManagement.subscriptionDetails.previousDelivery': 'Letzte Auftragsanlage',
  'subscriptionProductManagement.subscriptionDetails.nextDelivery': 'Nächste Auftragsanlage',
  'subscriptionProductManagement.subscriptionDetails.customerDetails': 'Kundendaten',

  'subscriptionProductManagement.subscriptionChangeHistory.STATUS_UPDATED': 'Stand aktualisiert',
  'subscriptionProductManagement.subscriptionChangeHistory.CREATED': 'Abo erstellt',
  'subscriptionProductManagement.subscriptionChangeHistory.GENERATED_ORDER_FAILED': 'Auftragserstellung fehlgeschlagen',
  'subscriptionProductManagement.subscriptionChangeHistory.DELIVERY_DATE_UPDATED':
    'Nächste Lieferung aktualisiert',
  'subscriptionProductManagement.subscriptionChangeHistory.PRODUCT_QUANTITY_UPDATED':
    'Menge aktualisiert',
  'subscriptionProductManagement.subscriptionChangeHistory.ROTATION_UPDATED': 'Turnus aktualisiert',
  'subscriptionProductManagement.subscriptionChangeHistory.GENERATED_ORDER': 'Auftrag generiert',
  'subscriptionProductManagement.subscriptionChangeHistory.PAYMENT_UPDATED': 'Zahlungsarten',
  'subscriptionProductManagement.subscriptionChangeHistory.DELIVERY_ADDRESS_UPDATED': 'Lieferadresse',
  'subscriptionProductManagement.subscriptionChangeHistory.CUSTOM_NOTE': 'Notiz hinzugefügt',
  'subscriptionProductManagement.subscriptionChangeHistory.DELIVERY_PRODUCT_NUMBER_UPDATED': 'Liefer BNR',
  'subscriptionProductManagement.subscriptionChangeHistory.SUBSCRIPTION_PRODUCT_NUMBER_UPDATED': 'Werbe BNR',

  'subscriptionProductManagement.subscriptionChangeHistory.title': 'Geschichte',
  'subscriptionProductManagement.subscriptionChangeHistory.sorting.OLD_FIRST': 'Frühere zuerst',
  'subscriptionProductManagement.subscriptionChangeHistory.sorting.NEW_FIRST': 'Neueste zuerst',
  'subscriptionProductManagement.subscriptionChangeHistory.wilisAgent': 'Wilis Agent: ',
  'subscriptionProductManagement.subscriptionChangeHistory.automaticUpdate': 'Automatisches Update',
  'subscriptionProductManagement.subscriptionChangeHistory.automaticUpdateNote':
    'Dies ist ein automatisches Update',
  'subscriptionProductManagement.subscriptionChangeHistory.newDeliveryDate': 'Neuer Liefertermin: ',
  'subscriptionProductManagement.subscriptionChangeHistory.turnus': 'Turnus: ',
  'subscriptionProductManagement.subscriptionChangeHistory.payment': 'Zahlungsarten: ',
  'subscriptionProductManagement.notes': 'Notiz',
  'subscriptionProductManagement.notes.clickToEdit': 'Hier klicken, um Notiz anzulegen',
  'subscriptionProductManagement.notes.editNote': 'Notiz bearbeiten',
  'subscriptionProductManagement.notes.editNote.successNotificationHint': 'Die Notiz wurde erfolgreich bearbeitet',
  'subscriptionProductManagement.notes.saveNote.successNotificationHint': 'Die Notiz wurde erfolgreich gespeichert',
  orderId: 'Internet ANR',
  'subscriptionManagement.productReport.viewSubscriptions': 'Abos ansehen',
  'subscriptionManagement.productReport.addNote': 'Notiz hinzufügen',
  'subscriptionManagement.productReport.addCustomEventPlaceholder': 'Details zu Briefversand/E-Mail/Kundenkontakt hinzufügen',
} as { [key: string]: string };
